import { CORE_UI_STATE_FEATURE_KEY, CoreUiState, coreEffects, coreReducer } from '@abbadox-monorepo/core-data-access';
import {
  ERROR_HANDLER_STATE_FEATURE_KEY,
  ErrorHandlerState,
  errorHandlerEffects,
  errorHandlerReducer,
} from '@abbadox-monorepo/core-error-handler';
import { IDLE_STATE_FEATURE_KEY, IdleState, idleEffects, idleReducer } from '@abbadox-monorepo/core-idle';
import {
  AUTH_STATE_FEATURE_KEY,
  AuthState,
  authApiEffects,
  authReducer,
} from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  PageState,
  WORKFLOWS_STATE_FEATURE_KEY,
  workflowsEffects,
  workflowsReducer,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  [AUTH_STATE_FEATURE_KEY]: AuthState;
  [IDLE_STATE_FEATURE_KEY]: IdleState;
  [CORE_UI_STATE_FEATURE_KEY]: CoreUiState;
  [WORKFLOWS_STATE_FEATURE_KEY]: PageState;
  [ERROR_HANDLER_STATE_FEATURE_KEY]: ErrorHandlerState;
  router: RouterReducerState<any>;
}

export const appReducers: ActionReducerMap<AppState> = {
  [AUTH_STATE_FEATURE_KEY]: authReducer,
  [IDLE_STATE_FEATURE_KEY]: idleReducer,
  [CORE_UI_STATE_FEATURE_KEY]: coreReducer,
  [WORKFLOWS_STATE_FEATURE_KEY]: workflowsReducer,
  [ERROR_HANDLER_STATE_FEATURE_KEY]: errorHandlerReducer,
  router: routerReducer,
};

export const effects = [errorHandlerEffects, authApiEffects, idleEffects, coreEffects, ...workflowsEffects];
