import { AppointmentsApiActions, AppointmentsPageActions } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { BillingPageActions } from '@abbadox-monorepo/kiosk-billing-data-access';
import { PatientRecordsApiActions } from '@abbadox-monorepo/kiosk-patient-data-access';
import { KioskFooter, KioskHeaderComponent } from '@abbadox-monorepo/kiosk-ui';
import {
  WorkflowUIActions,
  selectHasFooterActions,
  selectPageFooter,
  selectPageHeader,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, PushPipe, RouterModule, KioskHeaderComponent, KioskFooter],
  template: `
    @if (pageHeaderConfig$ | ngrxPush; as header) {
      <kiosk-header [header]="header"></kiosk-header>
    }

    <main class="relative mx-auto max-w-[50.125rem] p-4">
      <router-outlet></router-outlet>
    </main>

    @if ((pageFooterActions$ | ngrxPush) && (pageFooterConfig$ | ngrxPush); as footer) {
      <kiosk-footer
        [footer]="footer"
        (onPrevStepClicked)="navigateToPrevStep($event)"
        (onRestartWorkflowClicked)="navigateToWorkflowsStart()"
        (onNextStepClicked)="navigateToNextStep($event)"
      ></kiosk-footer>
    }
  `,
})
export class AppComponent {
  readonly store = inject(Store<AppState>);

  readonly pageHeaderConfig$ = this.store.select(selectPageHeader);
  readonly pageFooterConfig$ = this.store.select(selectPageFooter);
  readonly pageFooterActions$ = this.store.select(selectHasFooterActions);

  navigateToPrevStep(prevStep?: string) {
    this.store.dispatch(WorkflowUIActions.setPrevStep({ prevStep }));
  }

  navigateToWorkflowsStart() {
    this.store.dispatch(WorkflowUIActions.navigateToHomeFromRestartFooterButtonClick());
  }

  /**
   * Event bus for `nextPage` transitions.
   *
   * If the next step matches a route, actions for API will determine what happens next.
   * i.e. If the next route is from `check-in/authentication` to `check-in/verify-duplicate`, will trigger a form submit.
   *
   * Some actions require an API effect to navigate while other can navigate while side effects handle API calls.
   *
   * @param nextStep the next step in the check-in process
   */
  navigateToNextStep(nextStep?: string) {
    console.log(nextStep);
    this.store.dispatch(WorkflowUIActions.setNextStep({ nextStep }));

    switch (nextStep) {
      // on patient authorisation widget
      case 'patient-information':
        this.store.dispatch(PatientRecordsApiActions.loadPatientRecordsAttempted());
        break;
      // on patient information widget
      case 'appointment-verification':
        this.store.dispatch(PatientRecordsApiActions.submitPatientRecordCommentsAttempted());
        this.store.dispatch(AppointmentsPageActions.navigateToAppointments());
        break;
      // on appointment verification widget
      case 'forms':
      case 'billing':
        this.store.dispatch(AppointmentsApiActions.submitAppointmentsCommentAttempted());
        this.store.dispatch(BillingPageActions.navigateToBilling());
        break;
      case 'confirmation':
        this.store.dispatch(AppointmentsApiActions.submitAppointmentsCommentAttempted());
        this.store.dispatch(WorkflowUIActions.navigateToConfirmationPage());
        break;

      default:
        break;
    }
  }
}
