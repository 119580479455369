import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { CORE_UI_STATE_FEATURE_KEY, CoreUiState, coreEffects, coreReducer } from '@abbadox-monorepo/core-data-access';
import {
  ERROR_HANDLER_STATE_FEATURE_KEY,
  ErrorHandlerState,
  errorHandlerEffects,
  errorHandlerReducer,
} from '@abbadox-monorepo/core-error-handler';
import { IDLE_STATE_FEATURE_KEY, IdleState, idleEffects, idleReducer } from '@abbadox-monorepo/core-idle';
import {
  appointmentEffects,
  APPOINTMENTS_STATE_FEATURE_KEY,
  appointmentsReducer,
  AppointmentsState,
} from '@abbadox-monorepo/kiosk-appointments-data-access';
import {
  AUTH_STATE_FEATURE_KEY,
  AuthState,
  authApiEffects,
  authReducer,
} from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  BILLING_STATE_FEATURE_KEY,
  billingEffects,
  billingReducer,
  BillingState,
} from '@abbadox-monorepo/kiosk-billing-data-access';
import { EFORMS_STATE_FEATURE_KEY, EformsState, eformsReducer } from '@abbadox-monorepo/kiosk-eforms-data-access';
import {
  PATIENT_RECORDS_STATE_FEATURE_KEY,
  PATIENT_SEARCH_FORM_STATE_FEATURE_KEY,
  patientEffects,
  PatientRecordsState,
  patientSearchFormReducer,
  PatientSearchFormState,
  patientsRecordsReducer,
} from '@abbadox-monorepo/kiosk-patient-data-access';
import {
  PageState,
  WORKFLOWS_STATE_FEATURE_KEY,
  workflowsEffects,
  workflowsReducer,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

export interface AppState {
  [AUTH_STATE_FEATURE_KEY]: AuthState;
  [IDLE_STATE_FEATURE_KEY]: IdleState;
  [CORE_UI_STATE_FEATURE_KEY]: CoreUiState;
  [WORKFLOWS_STATE_FEATURE_KEY]: PageState;
  [PATIENT_SEARCH_FORM_STATE_FEATURE_KEY]: PatientSearchFormState;
  [PATIENT_RECORDS_STATE_FEATURE_KEY]: PatientRecordsState;
  [APPOINTMENTS_STATE_FEATURE_KEY]: AppointmentsState;
  [EFORMS_STATE_FEATURE_KEY]: EformsState;
  [BILLING_STATE_FEATURE_KEY]: BillingState;
  [ERROR_HANDLER_STATE_FEATURE_KEY]: ErrorHandlerState;
  router: RouterReducerState<any>;
}

export const appReducers: ActionReducerMap<AppState> = {
  [AUTH_STATE_FEATURE_KEY]: authReducer,
  [IDLE_STATE_FEATURE_KEY]: idleReducer,
  [CORE_UI_STATE_FEATURE_KEY]: coreReducer,
  [WORKFLOWS_STATE_FEATURE_KEY]: workflowsReducer,
  [PATIENT_SEARCH_FORM_STATE_FEATURE_KEY]: patientSearchFormReducer,
  [PATIENT_RECORDS_STATE_FEATURE_KEY]: patientsRecordsReducer,
  [APPOINTMENTS_STATE_FEATURE_KEY]: appointmentsReducer,
  [EFORMS_STATE_FEATURE_KEY]: eformsReducer,
  [BILLING_STATE_FEATURE_KEY]: billingReducer,
  [ERROR_HANDLER_STATE_FEATURE_KEY]: errorHandlerReducer,
  router: routerReducer,
};

export const effects = [
  errorHandlerEffects,
  authApiEffects,
  idleEffects,
  coreEffects,
  ...workflowsEffects,
  ...patientEffects,
  ...appointmentEffects,
  ...billingEffects,
];
