import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiHttpService } from '@abbadox-monorepo/core-http-client';

@Injectable({
  providedIn: 'root',
})
export class EformsHttpService {
  private readonly apiHttpService = inject(ApiHttpService);

  openEformsApi(payload: any): Observable<any> {
    const s = '';
    return this.apiHttpService.get<any>('/users/profile');
  }
}
