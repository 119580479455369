import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import { EformsApiActions, EformsState, selectEformsPayload } from './eforms.state';
import { EformsHttpService } from './services/eforms-http-client.service';

/** Load eForms URL from Careflow */
export const getEformsApiUrl$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store<EformsState>), eformsHttpService = inject(EformsHttpService)) =>
    actions$.pipe(
      ofType(EformsApiActions.loadEformsUrl),
      withLatestFrom(store.select(selectEformsPayload)),
      switchMap(([, payload]) =>
        eformsHttpService.openEformsApi(payload).pipe(
          map(({ eformsUrl }) => EformsApiActions.loadEformsUrlSuccess({ eformsUrl })),
          catchError((error: HttpErrorResponse) => of(EformsApiActions.loadEformsUrlFailed({ error: error.message }))),
        ),
      ),
    ),
  { functional: true },
);
