import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { selectAppointmentIds } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { selectAccountName } from '@abbadox-monorepo/kiosk-auth-data-access';
import { selectPatientIdMRN } from '@abbadox-monorepo/kiosk-patient-data-access';

export const EformsApiActions = createActionGroup({
  source: 'Eforms Api',
  events: {
    loadEformsUrl: emptyProps(),
    loadEformsUrlSuccess: props<{ eformsUrl: string }>(),
    loadEformsUrlFailed: props<{ error: string }>(),
  },
});

export const EformsPageActions = createActionGroup({
  source: 'Eforms Page',
  events: {
    navigateToEforms: emptyProps(),
  },
});

export interface EformsState {
  eformsUrl: string;
  error: any;
}

export const initialEformsState: EformsState = {
  eformsUrl: '',
  error: null,
};

export const eformsFeature = createFeature({
  name: 'eforms',
  reducer: createReducer(
    initialEformsState,
    on(EformsApiActions.loadEformsUrlSuccess, (state, { eformsUrl }) => ({
      ...state,
      eformsUrl,
    })),
  ),
});

export const { name: EFORMS_STATE_FEATURE_KEY, reducer: eformsReducer, selectEformsUrl } = eformsFeature;

export const selectEformsPayload = createSelector(
  selectAppointmentIds,
  selectPatientIdMRN,
  selectAccountName,
  (appointmentIds, patientMRN, accountName) => ({
    appointmentIds,
    patientMRN,
    accountName,
  }),
);
