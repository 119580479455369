import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, tap } from 'rxjs';

import { selectPatient } from '@abbadox-monorepo/kiosk-patient-data-access';
import {
  selectCurrentStep,
  selectNextStep,
  selectSelectedWorkflow,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

import { EformsPageActions, EformsState } from './eforms.state';

/** Navigate to eforms page. */
export const navigateToEforms$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store<EformsState>), router = inject(Router)) =>
    actions$.pipe(
      ofType(EformsPageActions.navigateToEforms),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectCurrentStep),
        store.select(selectNextStep),
        store.select(selectPatient),
      ),
      tap(([_, workflow, currentStep, nextStep, patient]) => {
        if (patient && nextStep === 'patient-forms') {
          router.navigate([workflow, currentStep, patient.autoCount, nextStep]);
        }
      }),
    ),
  { functional: true, dispatch: false },
);
