import { Route } from '@angular/router';

import { authGuard, profileGuard } from '@abbadox-monorepo/kiosk-auth-data-access';
import { NotFoundPage } from '@abbadox-monorepo/kiosk-ui';
import { workflowsGuard } from '@abbadox-monorepo/kiosk-workflows-data-access';

export const appRoutes: Route[] = [
  {
    path: 'home',
    loadChildren: () => import('@abbadox-monorepo/kiosk-home-feature-home').then((m) => m.routes),
    canMatch: [authGuard, profileGuard, workflowsGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('@abbadox-monorepo/kiosk-auth-feature-auth').then((m) => m.routes),
  },
  {
    path: ':workflow/:stepName',
    canMatch: [authGuard, profileGuard, workflowsGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@abbadox-monorepo/kiosk-patient-feature-patient').then((m) => m.routes),
      },
    ],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: NotFoundPage },
];
